export const MOH25 = {
    id: "MOH25",
    name: "MOH 25",
    clickable: false,
    residential_Unit_Type: "Villa",
    scope: "Design & Develop",
    status: "Under Development",
    location: "Al Mohammadiyyah",
    link_text: "Coming Soon",
    images: [],
    main_images: [],
    no_image_text: "Coming Soon",
};
