import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import './AboutUs.css';
import aboutUsImage from '../../assets/aboutUs.png';
import aboutUsImage2 from '../../assets/AboutUs2.jpg';
import DeemaAlkassim from '../../assets/Deema AlKassim.png';
import AbdulazizAlkassim from '../../assets/Abdulaziz AlKassim.png';
import AmnaJazeel from '../../assets/Amna Jazeel.png';
import Samia from '../../assets/Samia.jpg';
import SandraNasr from '../../assets/sandra.jpeg';
import TaherOrabi from '../../assets/taher-ourabi.jpeg';
import Abudelah from '../../assets/abdulelah.png';
import ReemaAlkassim from '../../assets/ReemaAlkassim-min.png';
import HessaAlkassim from '../../assets/hessa.jpeg';
import UnknownMale from '../../assets/unknown-male-person.jpg';

import StickyBox from 'react-sticky-box';
import { Row, Col } from 'react-bootstrap';
import { Fade } from "react-awesome-reveal";

const AboutUs = () => {
    const { t } = useTranslation();

    // Basic employee data - no ref-related properties needed
    const employeeData = [
        { position: 'co_founder', name: 'aziz_alkassim', image: AbdulazizAlkassim },
        { position: 'co_founder', name: 'deema_alkassim', image: DeemaAlkassim },
        { position: 'sales_marketing_head', name: 'reema_alkassim', image: ReemaAlkassim },
        { position: 'assistant_marketing_officer', name: 'sandra_nasr', image: SandraNasr },
        { position: 'operations_analyst', name: 'hessa_alkassim', image: HessaAlkassim },
        { position: 'finance_manager', name: 'aziz_ayyad', image: UnknownMale },
        { position: 'architect', name: 'amna_jazeel', image: AmnaJazeel },
        { position: 'architect', name: 'SamiaMonirul', image: Samia },
        { position: 'hr_officer', name: 'nehal_alali', image: UnknownMale },
        { position: 'site_engineer', name: 'abdulelah_almarzouk', image: Abudelah },
        { position: 'site_operations_manager', name: 'osama_issa', image: UnknownMale },
        { position: 'site_engineer', name: 'taher_oraby', image: TaherOrabi },
    ];

    // Create employees array with dynamic refs for second column items
    const employees = employeeData.map((employee, index) => ({
        ...employee,
        ref: index % 2 === 1 ? React.createRef() : null // Ref only for second column (odd indices)
    }));

    const [activeIndex, setActiveIndex] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const updateStickyPosition = () => {
        const stickyContainer = document.querySelector('.sticky-box');
        if (stickyContainer) {
            const windowHeight = window.innerHeight;
            const containerHeight = stickyContainer.offsetHeight;
            stickyContainer.style.top = `${Math.max((windowHeight - containerHeight) / 2, 0)}px`;
        }
    };

    const handleScroll = () => {
        updateStickyPosition();
        const centerY = window.innerHeight / 2;
        const valueElements = document.querySelectorAll('.value');

        const newActiveIndex = Array.from(valueElements).findIndex(element => {
            const rect = element.getBoundingClientRect();
            return rect.top < centerY && rect.bottom > centerY;
        });

        setActiveIndex(newActiveIndex);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                entry.target.classList.toggle('visible', entry.isIntersecting);
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5
        });

        employees.forEach(employee => {
            if (employee.ref?.current) observer.observe(employee.ref.current);
        });

        return () => {
            employees.forEach(employee => {
                if (employee.ref?.current) observer.unobserve(employee.ref.current);
            });
        };
    }, [employees]);

    const EmployeeCard = ({ employee, isSecondColumn }) => (
        <div
            className={`profile-card ${isSecondColumn ? 'sep' : ''}`}
            ref={employee.ref}
        >
            <Row>
                <Col xs={7} md={7} lg={7}>
                    <div className="profile-text-container">
                        <p className="profile-position">{t(`aboutUs.${employee.position}`)}</p>
                        <h5 className="profile-name">{t(`aboutUs.${employee.name}`)}</h5>
                    </div>
                </Col>
                <Col xs={5} md={5} lg={5}>
                    <div className="profile-image-container">
                        <img
                            src={employee.image}
                            alt={t(`aboutUs.${employee.name}`)}
                            className="profile-image center"
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );

    return (
        <>
            <Navbar />

            <Fade>
                <div className="about-us-section">
                    <Row style={{ width: '100%' }}>
                        <Col xs={12} md={12} lg={4} className="about-us-text-section left-section">
                            <h3 className="about-us-title">{t('aboutUs.title')}</h3>
                            <p className="about-us-text">{t('aboutUs.text')}</p>
                            <p className="about-us-text">{t('aboutUs.text2')}</p>
                        </Col>
                        <Col xs={12} md={12} lg={8}>
                            <img src={aboutUsImage} alt="about us" className="about-us-image" />
                        </Col>
                    </Row>
                </div>
            </Fade>

            <Fade>
                <div className="about-us-section text-section">
                    <Row>
                        <Col>
                            <p
                                className="about-us-section-text"
                                dangerouslySetInnerHTML={{ __html: t('aboutUs.text3') }}
                            />
                            <p className="about-us-section-text">{t('aboutUs.text4')}</p>
                        </Col>
                    </Row>
                </div>
            </Fade>

            <Fade>
                <div className="about-us-section">
                    <Row>
                        <Col xs={12} md={12} lg={6}>
                            <div className="about-us-image-container">
                                <img src={aboutUsImage2} alt="about us" className="about-us-image center" />
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={6} className="about-us-text-section right-section">
                            <h3 className="about-us-title">{t('aboutUs.our_history')}</h3>
                            <p className="about-us-text">{t('aboutUs.our_history_text')}</p>
                        </Col>
                    </Row>
                </div>
            </Fade>

            <Fade>
                <div className="about-us-section">
                    <Row>
                        <Col xs={4} md={4} lg={6} className="about-us-text-section">
                            <StickyBox className="sticky-box">
                                <h3 className="about-us-title">{t('aboutUs.our_values')}</h3>
                            </StickyBox>
                        </Col>
                        <Col xs={8} md={8} lg={6} className="about-us-text-section">
                            {[1, 2, 3, 4, 5, 6].map(index => (
                                <div
                                    key={index}
                                    className={`value
                                        ${activeIndex === index - 1 ? 'active' : ''}
                                        ${index === 1 ? 'first' : ''}
                                        ${index === 6 ? 'last' : ''}`}
                                >
                                    <p>{t(`aboutUs.value${index}`)}</p>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </div>
            </Fade>

            <Fade>
                <div className="about-us-section team-section">
                    <Row>
                        <h3 className="about-us-title">{t('aboutUs.our_team')}</h3>
                    </Row>

                    {/* Employee grid */}
                    {employees.reduce((rows, employee, index) => {
                        if (index % 2 === 0) {
                            rows.push([employee]);
                        } else {
                            rows[rows.length - 1].push(employee);
                        }
                        return rows;
                    }, []).map((row, rowIndex) => (
                        <Row key={rowIndex} style={{ width: '100%' }}>
                            {row.map((employee, colIndex) => (
                                <Col key={colIndex} xs={12} md={12} lg={6}>
                                    <EmployeeCard
                                        employee={employee}
                                        isSecondColumn={colIndex === 1}
                                    />
                                </Col>
                            ))}
                        </Row>
                    ))}
                </div>
            </Fade>

            <Footer />
        </>
    );
};

export default AboutUs;