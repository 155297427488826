
import { Mohamadiyah } from './Mohamadiyah';
import { AlUyaynahRetreat } from './AlUyaynahRetreat';
import { StayHittin } from './StayHittin';
import { OfficesRyiadh } from './OfficesRyiadh';
import { StayAlNakheel } from './StayAlNakheel';
import { STAYKSU } from './STAYKSU';
import { DrfahOffice } from './DrfahOffice';
import { StayAlYasmin } from './StayAlYasmin';
import { StayAlJubayla } from './StayAlJubayla';
import { MOH25 } from './MOH25';

import { Mohamadiyah_ar } from './Mohamadiyah_ar';
import { AlUyaynahRetreat_ar } from './AlUyaynahRetreat ar';
import { StayHittin_ar } from './StayHittin_ar';
import { OfficesRyiadh_ar } from './OfficesRyiadh_ar';
import { StayAlNakheel_ar } from './StayAlNakheel_ar';
import { STAYKSU_ar } from './STAYKSU_ar';
import { DrfahOffice_ar } from './DrfahOffice_ar';
import { StayAlYasmin_ar } from './StayAlYasmin_ar';
import { StayAlJubayla_ar } from './StayAlJubayla_ar';
import { MOH25_ar } from './MOH25_ar';



export const Projects = [
    StayAlJubayla,
    MOH25,
    Mohamadiyah,
    AlUyaynahRetreat,
    StayHittin,
    OfficesRyiadh,
    StayAlNakheel,
    STAYKSU,
    DrfahOffice,
    StayAlYasmin
]

export const Projects_ar = [
    StayAlJubayla_ar,
    MOH25_ar,
    Mohamadiyah_ar,
    AlUyaynahRetreat_ar,
    StayHittin_ar,
    OfficesRyiadh_ar,
    StayAlNakheel_ar,
    STAYKSU_ar,
    DrfahOffice_ar,
    StayAlYasmin_ar
]


