import img1 from "../assets/projects/StayAlJubayla/images/1.jpg";
import img2 from "../assets/projects/StayAlJubayla/images/2.jpg";
import img3 from "../assets/projects/StayAlJubayla/images/3.jpg";
import img4 from "../assets/projects/StayAlJubayla/images/4.jpg";
import img5 from "../assets/projects/StayAlJubayla/images/5.jpg";
import img6 from "../assets/projects/StayAlJubayla/images/6.jpg";
import img8 from "../assets/projects/StayAlJubayla/images/8.jpg";
import img9 from "../assets/projects/StayAlJubayla/images/9.jpg";
import img10 from "../assets/projects/StayAlJubayla/images/10.jpg";
import img11 from "../assets/projects/StayAlJubayla/images/11.jpg";
import img12 from "../assets/projects/StayAlJubayla/images/12.jpg";


import thm1 from "../assets/projects/StayAlJubayla/thumbnails/1_tn.jpg";
import thm2 from "../assets/projects/StayAlJubayla/thumbnails/2_tn.jpg";
import thm3 from "../assets/projects/StayAlJubayla/thumbnails/3_tn.jpg";
import thm4 from "../assets/projects/StayAlJubayla/thumbnails/4_tn.jpg";
import thm5 from "../assets/projects/StayAlJubayla/thumbnails/5_tn.jpg";
import thm6 from "../assets/projects/StayAlJubayla/thumbnails/6_tn.jpg";
import thm8 from "../assets/projects/StayAlJubayla/thumbnails/8_tn.jpg";
import thm9 from "../assets/projects/StayAlJubayla/thumbnails/9_tn.jpg";
import thm10 from "../assets/projects/StayAlJubayla/thumbnails/10_tn.jpg";
import thm11 from "../assets/projects/StayAlJubayla/thumbnails/11_tn.jpg";
import thm12 from "../assets/projects/StayAlJubayla/thumbnails/12_tn.jpg";

import plan from "../assets/projects/StayAlJubayla/floorplanes/Floor Plan.jpg";


export const StayAlJubayla_ar = {
    id: "StayAlJubayla",
    name: "ستاي الجبيلة",
    residential_Unit_Type: "شقق",
    scope: "تطوير",
    status: "قيد التطوير",
    location: "الجبيلة",
    area: "1,599 م²",
    clickable: true,
    stats: {
        units: {
            value: 54,
            unit: "",
        },
        floors_in_unit: {
            value: 1,
            unit: "",
        },
        built_up_area_per_unit: {
            value: 69,
            unit: "م²",
        },
        plot_area_per_unit: {
            value: "10,255",
            unit: "م²",
        },
    },
    nearby_description: "يقع المشروع بجوار أجمل الأراضي الزراعية في الرياض، ويقدم مزيجًا فريدًا من الراحة الحضرية والهدوء الريفي",
    team: [
        "عبدالعزيز القاسم",
        "ديمة القاسم",
        "عبدالإله المرزوق",
        "أمنة جزيل",
        "سامية منير الإسلام",
    ],
    main_images: [
        img1,
        img2,
        img3,
    ],
    text_sections: [
        "ستاي في الجبيلة مشروع يقدم تجربة سكنية فريدة، يعالج احتياجات المسكن الحالية بتصميم حديث. يركز التطوير على توفير مساحات وظيفية تلبي متطلبات المنزل الحديث، مع دمج مميزات الاستدامة.",
        "يتميز تصميم المشروع بواجهة مزدوجة فريدة تميزه عن البيئة المحيطة، مما يعكس التزام ستاي بمعايير الاستدامة في التصميم. ومن داخله فناء يعزل ما بين الوحدات، مما يسمح لأشعة ضوء الشمس تتداخل مع مناظر مميزة من جميع طوابق المبنى الثلاثة. يشمل المشروع 54 وحدة سكنية، مصممة لتحقيق أقصى حد من الراحة والرفاهية للسكان. تتراوح الشقق من غرفة نوم واحدة إلى غرفتين وتحتوي على منطقة معيشة مريحة ومنطقة طعام ومطبخ وحمامات.",
        "تم تصميم المساحة بشكل مثالي مما يوفر مناطق مميزة للمعيشة وتناول الطعام والطهي والاسترخاء لتكون كل منطقة متعددة الاستخدامات ومتعددة الوظائف، لتلبية جميع احتياجات الساكن.",
    ],
    section_images: {},
    plans_cover: plan,
    plnas_description: "تستفيد التصاميم من المساحة بشكل مثالي، مما يوفر مناطق مميزة للمعيشة وتناول الطعام والطهي والاسترخاء. تم تصميم كل منطقة لتكون متعددة الاستخدامات ومتعددة الوظائف، لتلبية مجموعة متنوعة من الاحتياجات والأنشطة.",
    location_dev: {
        latitude: 24.901834,
        longitude: 46.435208,
        google_maps_url: "https://maps.app.goo.gl/iJfxF5aCmgiLFih76?g_st=iw",
    },
    images: [
        {
            image: img1,
            thumbnail: thm1,
        },
        {
            image: img2,
            thumbnail: thm2,
        },
        {
            image: img3,
            thumbnail: thm3,
        },
        {
            image: img4,
            thumbnail: thm4,
        },
        {
            image: img5,
            thumbnail: thm5,
        },
        {
            image: img6,
            thumbnail: thm6,
        },
        {
            image: img8,
            thumbnail: thm8,
        },
        {
            image: img9,
            thumbnail: thm9,
        },
        {
            image: img10,
            thumbnail: thm10,
        },
        {
            image: img11,
            thumbnail: thm11,
        },
        {
            image: img12,
            thumbnail: thm12,
        },
    ],
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {},
    floorPlans: [
        {
            name: "الوحدة",
            img: plan,
            area: "69 م²",
            descriptionList: [
                "1 غرفة نوم",
                "2 منطقة معيشة",
                "3 منطقة طعام",
                "4 حمام",
                "5 مطبخ",
            ],
        },
    ],
};
