
import mainImage from "../assets/projects/Mohamadiya/EAST-ELEVATION-min.jpg";
import mainFloor from "../assets/projects/Mohamadiya/main-floor.gif";
import moreimage1 from "../assets/projects/Mohamadiya/WESTSOUTH-ELEVATION-min.jpg";
import moreimage2 from "../assets/projects/Mohamadiya/images/5.jpg";
import openerVideo from "../assets/projects/Mohamadiya/villa-moh.mp4";

import image2 from "../assets/projects/Mohamadiya/EAST-ELEVATION-min.jpg";
import image9 from "../assets/projects/Mohamadiya/GROUNDFLOOR-LIVINGROOMPOOL-min.jpg";
import image11 from "../assets/projects/Mohamadiya/WESTSOUTH-ELEVATION-min.jpg";

import img1 from "../assets/projects/Mohamadiya/images/1.jpg";
import img2 from "../assets/projects/Mohamadiya/images/2.jpg";
import img3 from "../assets/projects/Mohamadiya/images/3.jpg";
import img4 from "../assets/projects/Mohamadiya/images/4.jpg";
import img5 from "../assets/projects/Mohamadiya/images/5.jpg";
import img6 from "../assets/projects/Mohamadiya/images/6.jpg";
import img7 from "../assets/projects/Mohamadiya/images/7.jpg";
import img8 from "../assets/projects/Mohamadiya/images/8.jpg";
import img9 from "../assets/projects/Mohamadiya/images/9.jpg";
import img10 from "../assets/projects/Mohamadiya/images/10.jpg";
import img11 from "../assets/projects/Mohamadiya/images/11.jpg";
import img12 from "../assets/projects/Mohamadiya/images/12.jpg";
import img13 from "../assets/projects/Mohamadiya/images/13.jpg";
import img15 from "../assets/projects/Mohamadiya/images/15.jpg";
import img16 from "../assets/projects/Mohamadiya/images/16.jpg";
import img17 from "../assets/projects/Mohamadiya/images/17.jpg";
import img18 from "../assets/projects/Mohamadiya/images/18.jpg";
import img19 from "../assets/projects/Mohamadiya/images/19.jpg";
import img20 from "../assets/projects/Mohamadiya/images/20.jpg";
import img21 from "../assets/projects/Mohamadiya/images/21.jpg";
import img22 from "../assets/projects/Mohamadiya/images/22.jpg";
import img23 from "../assets/projects/Mohamadiya/images/23.jpg";
import img24 from "../assets/projects/Mohamadiya/images/24.png";
import img25 from "../assets/projects/Mohamadiya/images/25.jpg";
import img26 from "../assets/projects/Mohamadiya/images/26.jpg";
import img27 from "../assets/projects/Mohamadiya/images/27.jpg";
import img28 from "../assets/projects/Mohamadiya/images/28.jpg";

import thm1 from "../assets/projects/Mohamadiya/images/thumb/1_tn.jpg";
import thm2 from "../assets/projects/Mohamadiya/images/thumb/2_tn.jpg";
import thm3 from "../assets/projects/Mohamadiya/images/thumb/3_tn.jpg";
import thm4 from "../assets/projects/Mohamadiya/images/thumb/4_tn.jpg";
import thm5 from "../assets/projects/Mohamadiya/images/thumb/5_tn.jpg";
import thm6 from "../assets/projects/Mohamadiya/images/thumb/6_tn.jpg";
import thm7 from "../assets/projects/Mohamadiya/images/thumb/7_tn.jpg";
import thm8 from "../assets/projects/Mohamadiya/images/thumb/8_tn.jpg";
import thm9 from "../assets/projects/Mohamadiya/images/thumb/9_tn.jpg";
import thm10 from "../assets/projects/Mohamadiya/images/thumb/10_tn.jpg";
import thm11 from "../assets/projects/Mohamadiya/images/thumb/11_tn.jpg";
import thm12 from "../assets/projects/Mohamadiya/images/thumb/12_tn.jpg";
import thm13 from "../assets/projects/Mohamadiya/images/thumb/13_tn.jpg";
import thm15 from "../assets/projects/Mohamadiya/images/thumb/15_tn.jpg";
import thm16 from "../assets/projects/Mohamadiya/images/thumb/16_tn.jpg";
import thm17 from "../assets/projects/Mohamadiya/images/thumb/17_tn.jpg";
import thm18 from "../assets/projects/Mohamadiya/images/thumb/18_tn.jpg";
import thm19 from "../assets/projects/Mohamadiya/images/thumb/19_tn.jpg";
import thm20 from "../assets/projects/Mohamadiya/images/thumb/20_tn.jpg";
import thm21 from "../assets/projects/Mohamadiya/images/thumb/21_tn.jpg";
import thm22 from "../assets/projects/Mohamadiya/images/thumb/22_tn.jpg";
import thm23 from "../assets/projects/Mohamadiya/images/thumb/23_tn.jpg";
import thm24 from "../assets/projects/Mohamadiya/images/thumb/24_tn.jpg";
import thm25 from "../assets/projects/Mohamadiya/images/thumb/25_tn.jpg";
import thm26 from "../assets/projects/Mohamadiya/images/thumb/26_tn.jpg";
import thm27 from "../assets/projects/Mohamadiya/images/thumb/27_tn.jpg";
import thm28 from "../assets/projects/Mohamadiya/images/thumb/28_tn.jpg";

import thum2 from "../assets/projects/Mohamadiya/thumbnails/EAST-ELEVATION-min_tn.jpg";
import thum9 from "../assets/projects/Mohamadiya/thumbnails/GROUNDFLOOR-LIVINGROOMPOOL-min_tn.jpg";
import thum11 from "../assets/projects/Mohamadiya/thumbnails/WESTSOUTH-ELEVATION-min_tn.jpg";

import plan from "../assets/projects/Mohamadiya/site plan/plan.png";
import villa1 from "../assets/projects/Mohamadiya/site plan/villa1.png";
import villa2 from "../assets/projects/Mohamadiya/site plan/villa2.png";
import villa3 from "../assets/projects/Mohamadiya/site plan/villa3.png";


import Basement from "../assets/projects/Mohamadiya/floor plans/basment.png";
import GroundFloor from "../assets/projects/Mohamadiya/floor plans/ground.png";
import FirstFloor from "../assets/projects/Mohamadiya/floor plans/1st.png";
import SecondFloor from "../assets/projects/Mohamadiya/floor plans/2nd.png";

export const Mohamadiyah = {
    id: "mohamadiyah",
    name: "MOH 24",
    residential_Unit_Type: "Villa",
    scope: "Design & Develop",
    status: "Sold",
    starting_price: 4900000,
    starting_price_text: "4 Million and 900 Thousands",
    number_of_bedrooms: 4,
    clickable: true,
    location: "Al Mohammadiyyah",
    area: "1,599 m²",
    stats: {
        units: {
            value: 3,
            unit: "",
        },
        floors_in_unit: {
            value: 4,
            unit: "",
        },
        built_up_area_per_unit: {
            value: 533,
            unit: "m²",
        },
        plot_area_per_unit: {
            value: 300,
            unit: "m²",
        },
    },
    nearby_description: "A Prime location. Situated at the junction of King Abdullah and Al Takhasusi intersections, the homes provide convenient access from two different streets. The front entrance is located on one street, while the service entrance and garages are situated on the other street. The new metro station 120 meters distant and Sulaiman Al Habib Medical Hospital just 250 meters beyond. The surrounding neighborhood pulsates with life, offering gastronomy, commerce, and vital medical services.",
    team: [
        "Abdulaziz Alkassim",
        "Deema Alkassim",
        "Abdulrahman Alshawbaki",
        "Amna Jazeel"
    ],
    opener_video: openerVideo,
    main_images: [
        mainImage,
        moreimage1,
        moreimage2,
    ],
    text_sections: [
        "In Riyadh's Al Mohamadiya district three interconnected villas are crafted to reflect the intricate dynamics of a Saudi family. The land area for each villa totals 300 square meters, with a built-up area of 533 square meters, seamlessly blending indoor and outdoor living areas to foster comfort and connection.",
        "A Prime location. Situated at the junction of King Abdullah and Al Takhasusi intersections, the homes provide convenient access from two different streets. The front entrance is located on one street, while the service entrance and garages are situated on the other street. The new metro station 120 meters distant and Sulaiman Al Habib Medical Hospital just 250 meters beyond. The surrounding neighborhood pulsates with life, offering gastronomy, commerce, and vital medical services.",
        "Drawing inspiration from the Saudi family's essence, the design mirrors our values in both spatial organization and façade treatment. Privacy reigns, ensuring individual comfort and tranquility allowing connection when desired. The architecture orchestrates the flow of movement, preventing clashes while facilitating co-existence within the shared space.",
        "The building's design emerged from a multi-step process: first, dividing the initial volume into three distinct levels. These levels were then shifted laterally, creating visual interest and dynamic interplay. Next, the third level was sculpted with recesses and projections, forming individual volumes within the overall form. Finally, terraces and openings were added to vitalize the design, connecting it to the environment and enriching the user experience. This progression, from volume to articulation and animation, defines the building's architectural identity.",
        "Each villa holds entrances facing at least two of the surrounding three streets. This flexibility caters to diverse functional needs and preferences. The west elevation is muted to shield the interiors from the direct sun. On the east, the architecture embraces transparency using glass, welcoming morning light. Southward, the façade maintains a veil of privacy, revealing glimpses to and from within, using windows, while preserving a sense of seclusion."
    ],
    section_images: {
        1: [mainFloor],
    },
    plans_cover: plan,
    plnas_description: "The villa features four living areas, three of which blur the lines between indoors and outdoors. Four bedrooms, including a master suite with a walk-in closet and double bath. An office, gym, and a rooftop entertainment area with a barbecue station. A dirty kitchen with a separate entrance and handy kitchenettes on each floor.",
    location_dev: {
        latitude: 24.72551744,
        longitude: 46.65553273,
        google_maps_url: "https://maps.app.goo.gl/5TyqwTZv8YQxNDgu5",
    },
    images: [
        {
            image: image2,
            description: "East Elevation",
            thumbnail: thum2,
        },
        {
            image: img1,
            thumbnail: thm1,
        },
        {
            image: img2,
            thumbnail: thm2,
        },
        {
            image: img3,
            thumbnail: thm3,
        },
        {
            image: img4,
            thumbnail: thm4,
        },
        {
            image: img5,
            thumbnail: thm5,
        },
        {
            image: img6,
            thumbnail: thm6,
        },
        {
            image: img7,
            thumbnail: thm7,
        },
        {
            image: img8,
            thumbnail: thm8,
        },
        {
            image: img9,
            thumbnail: thm9,
        },
        {
            image: img10,
            thumbnail: thm10,
        },
        {
            image: img11,
            thumbnail: thm11,
        },
        {
            image: img12,
            thumbnail: thm12,
        },
        {
            image: img13,
            thumbnail: thm13,
        },
        {
            image: img15,
            thumbnail: thm15,
        },
        {
            image: img16,
            thumbnail: thm16,
        },
        {
            image: img18,
            thumbnail: thm18,
        },
        {
            image: img19,
            thumbnail: thm19,
        },
        {
            image: img20,
            thumbnail: thm20,
        },
        {
            image: img21,
            thumbnail: thm21,
        },
        {
            image: img22,
            thumbnail: thm22,
        },
        {
            image: img23,
            thumbnail: thm23,
        },
        {
            image: img24,
            thumbnail: thm24,
        },
        {
            image: img25,
            thumbnail: thm25,
        },
        {
            image: img26,
            thumbnail: thm26,
        },
        {
            image: img27,
            thumbnail: thm27,
        },
        {
            image: img28,
            thumbnail: thm28,
        },
        {
            image: img17,
            description: "Ground Floor, Kitchen View.",
            thumbnail: thm17,
        },
        {
            image: image9,
            description: "Ground Floor, Pool to Living Area View.",
            thumbnail: thum9,
        },
        {
            image: image11,
            description: "View of West & South elevations of the project.",
            thumbnail: thum11,
        },
    ],
    hasPlansOverlay: true,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {
        cover: plan,
        villa1: villa1,
        villa2: villa2,
        villa3: villa3,
    },
    villaInof: {
        villa3: {
            price: 0,
            price_text: "unavailable",
            size: 533,
            availabile: false,
            name: "Villa 3",
        },
        villa2: {
            price: 0,
            price_text: "unavailable",
            size: 533,
            availabile: false,
            name: "Villa 2",
        },
        villa1: {
            price: 0,
            price_text: "unavailable",
            size: 533,
            availabile: false,
            name: "Villa 1",
        }

    },
    floorPlans: [
        {
            name: "Basement",
            img: Basement,
            area: "112 m²",
            descriptionList: [
                "1 Living Area",
                "2 Open Kitchen",
                "3 Office",
                "4 Gym",
                "5 Outdoor Area",
                "6 W.C.",
                "7 Elevator",
                "8 Staircase to Gate",
            ],
        },
        {
            name: "Ground",
            img: GroundFloor,
            area: "190 m²",
            descriptionList: [
                "1 Outdoor Areas",
                "2 Reception",
                "3 Living Area",
                "4 Dining Area",
                "5 Pool",
                "6 W.C.",
                "7 Elevator",
                "8 Staircase",
                "9 Laundry Room",
                "10 Kitchen",
                "11 Maid's Room",
                "12 Driver's Room",
                "13 Parking",
            ],
        },
        {
            name: "First Floor",
            img: FirstFloor,
            area: "171 m²",
            descriptionList: [
                "1 Master Bedroom",
                "2 Master Bathroom & Walk-In Closet",
                "3 Single Bedroom",
                "4 Double Bedroom",
                "5 Terrace",
                "6 W.C.",
                "7 Elevator",
                "8 Staircase",
                "9 Kitchenette",
            ],
        },
        {
            name: "Second Floor",
            img: SecondFloor,
            area: "60 m²",
            descriptionList: [
                "1 Living Area",
                "2 Rooftop Sitting Area",
                "3 Rooftop Dining Area",
                "4 Rooftop Kitchenette",
                "5 Service Area",
                "6 W.C.",
                "7 Elevator",
                "8 Staircase",
            ],
        },
    ],
};