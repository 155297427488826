const images = require.context("../assets/projects/DrfahOffice/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/DrfahOffice/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const DrfahOffice_ar = {
    id: "DrfahOffice",
    name: "مكاتب إدارية",
    residential_Unit_Type: "مكاتب",
    scope: "تصميم و تطوير",
    status: "مؤجر",
    starting_price: null,
    clickable: true,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "حي الرائد",
    area: "200 m²",
    stats: {},
    nearby_description: "يقع الموقع في حي الرائد على طريق الملك عبدالله",
    team: [
        "عبدالعزيز القاسم",
        "ديمه القاسم",
    ],
    main_images: [
        importedImages[3],
        importedImages[1],
        importedImages[2],
    ],
    text_sections: [
        "المكتب صمم ونفذ عام 2022، يمثل مساحة عمل حديثة وفعالة. يغمر ضوء الشمس التصميم من خلال النوافذ الكبيرة. تؤدي الأبواب المخفية إلى مناطق خدمة المكتب، مثل المطبخ والحمامات. يركز التصميم البسيط على خطوط نظيفة تعزز التركيز والإنتاجية.",
        "استخدام الخشب الطبيعي للمكاتب والنباتات الداخلية في جميع أنحاء المكتب يعزز شعور الاتصال بالطبيعة. تضيف طاولات الرخام في مناطق محددة لمسة من الأناقة واللون. يضع التصميم وألوانه الأولوية للموظفين من خلال توفير بيئة عمل مريحة، مما يضمن بيئة عمل منتجة ومريحة.",
        "يعكس المكتب قيم الشركة والتزامها ببيئة عمل تعاونية وحديثة وفعالة.",
    ],
    section_images: {},
    plans_cover: null,
    plnas_description: "",
    location_dev: {
        latitude: 24.71125,
        longitude: 46.6315833333,
        google_maps_url: "https://maps.app.goo.gl/Rn4uecoE7dpEpREQ9",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: false,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    floorPlans: [],
};