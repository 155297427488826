import React, { useState, useEffect } from 'react';
import './Project.css';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES } from '../../const/const';
import ArrowLink from '../ArrowLink/ArrowLink';
import { Reveal } from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";

function Project({ project, recent = false, readMore = false }) {
    const { t } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    // const mainImage = project.images && project.images.length > 0 ? project.images[0] : '';
    // const slideshowImages = project.images && project.images.length > 1 ? project.images.slice(1) : [];
    const enlarg = keyframes`
    from {
      transform: scale(0.9);
    }

    to {
      transform: scale(1);
    }
  `;

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (project.main_images) {
            const imagePromises = project.main_images.map((image) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = image;
                    img.onload = resolve;
                    img.onerror = reject;
                });
            });

            Promise.all(imagePromises)
                .then(() => { })
                .catch((error) => {
                    console.error("Error preloading images:", error);
                });


            return () => { };
        }
    }, [project.main_images]);

    useEffect(() => {
        let intervalId;

        if (isHovered) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % project.main_images.length);
            // Start the slideshow when hovered
            intervalId = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % project.main_images.length);
            }, 2000);
        } else {
            clearInterval(intervalId);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isHovered]);

    return (
        <>
            <Row>
                <Col xs={12} md={4} className="order-last order-md-first">
                    {recent && (
                        <div className="property-title">
                            {t('home.recent_projects.recent')}
                        </div>
                    )}
                    <div className="projects-name">
                        <a href={project.clickable ? APP_ROUTES.PROJECT_DETAILS + '?project_id=' + project.id : '#'}
                            style={{ cursor: 'pointer', textDecoration: 'none' }} className='projects-name'>
                            {project.name}
                        </a>
                    </div>
                    {project.location && (
                        <>
                            <div className="property-title">{t('home.recent_projects.location')}</div>
                            <div className="property-value">{project.location}</div>
                        </>
                    )}
                    <div className="property-title">{t('home.recent_projects.residential_unit_type')}</div>
                    <div className="property-value">{project.residential_Unit_Type}</div>

                    {project.status && (
                        <>
                            <div className="property-title">{t('home.recent_projects.status')}</div>
                            <div className="property-value">{project.status}</div>
                        </>
                    )}


                    <ArrowLink href={
                        project.clickable ? APP_ROUTES.PROJECT_DETAILS + '?project_id=' + project.id : ''}
                        text={project.link_text ? project.link_text :
                            t('home.recent_projects.more_info')} />


                </Col>
                <Col xs={12} md={8} className="order-first order-md-last">
                    <Reveal keyframes={enlarg} duration={500} className='h-100'>
                        <div
                            className='project-image-container h-100'
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => {
                                setIsHovered(false);
                            }}
                        >
                            {project.main_images && project.main_images.length > 0 ? (
                                <a href={project.clickable ? APP_ROUTES.PROJECT_DETAILS + '?project_id=' + project.id : '#'}>
                                    <img
                                        className={`project-image`}
                                        key={currentIndex}
                                        src={project.main_images.find((image, index) => index === currentIndex)}
                                        alt={project.name}
                                        style={readMore ? { maxHeight: '500px', height: '500px' } : {}}
                                    />
                                </a>
                            ) : (
                                <div className="h-100 d-flex align-items-center justify-content-center text-center">
                                    <h4>
                                        {project.no_image_text}
                                    </h4>
                                </div>

                            )}
                        </div>
                    </Reveal>
                </Col>

            </Row >
            {readMore && (
                <Row className="more-main-proj" style={{ width: '100%' }}>
                    <Col xs={12} md={12}>
                        <div className='d-flex justify-content-end mt-2'>
                            <ArrowLink href={APP_ROUTES.PROJECT_DETAILS + '?project_id=' + project.id}
                                text={t('home.recent_projects.link')} />
                        </div>
                    </Col>
                </Row>
            )}
            <div className='hr-separator'></div>
        </>
    );
}

export default Project;
