export const MOH25_ar = {
    id: "MOH25",
    name: "المحمدية 25",
    clickable: false,
    residential_Unit_Type: "فيلا",
    scope: "تصميم و تطوير",
    status: "قيد التطوير",
    location: "المحمدية",
    link_text: "قريباً",
    images: [],
    main_images: [],
    no_image_text: "قريباً",
};
