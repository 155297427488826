import A1 from "../assets/projects/StayAlYasmin/floorplans/A1-min.png";
import A2 from "../assets/projects/StayAlYasmin/floorplans/A2-min.png";
import A3 from "../assets/projects/StayAlYasmin/floorplans/A3-min.png";
import B1 from "../assets/projects/StayAlYasmin/floorplans/B1-min.png";
import B2 from "../assets/projects/StayAlYasmin/floorplans/B2-min.png";
import B3 from "../assets/projects/StayAlYasmin/floorplans/B3-min.png";
import C1 from "../assets/projects/StayAlYasmin/floorplans/C1-min.png";
import C2 from "../assets/projects/StayAlYasmin/floorplans/C2-min.png";
import D1 from "../assets/projects/StayAlYasmin/floorplans/D1-min.png";
import D2 from "../assets/projects/StayAlYasmin/floorplans/D2-min.png";

const images = require.context("../assets/projects/StayAlYasmin/images", false, /.*\.(png|jpe?g|svg)$/);
const thumbnailImages = require.context("../assets/projects/StayAlYasmin/thumbnails", false, /.*\.(png|jpe?g|svg)$/);
const importedImages = images.keys().map(images);
const importedThumbnailImages = thumbnailImages.keys().map(thumbnailImages);

export const StayAlYasmin = {
    id: "StayAlYasmin",
    name: "STAY at AlYasmin",
    residential_Unit_Type: "Apartments",
    scope: "Develop",
    status: "Rent",
    starting_price: null,
    starting_price_text: null,
    number_of_bedrooms: null,
    location: "Al Yasmin",
    area: "4,200 m²",
    clickable: true,
    stats: {
        units: {
            value: 33,
            unit: "",
        },
        built_up_area_per_unit: {
            prefix: "Up to",
            value: 190,
            unit: "m²",
        },
        floors_in_unit: {
            value: [2, 3],
            unit: "",
        },
        total_built_up_area: {
            value: "4,200",
            unit: "m²",
        }
    },
    nearby_description: "Strategically situated within the Al Yasmin District, the project offers effortless access to key destinations. King Khalid International Airport lies 15 kilometers away, while Park Avenue Mall is accessible at a distance of 8 kilometers. Furthermore, the project enjoys close proximity to Riyadh Front, a prominent business center, located just 8 kilometers away.",
    team: [
        "Abdulaziz Alkassim",
    ],
    main_images: [
        importedImages[4],
        importedImages[2],
        importedImages[1],
    ],
    text_sections: [
        "STAY is a Build to Rent Residential real estate development & multifamily property management company providing communities with exceptional facilities & amenities along numerous semi-furnished & furnished floor plans offering various lease periods that determine where you want to STAY since 2015.",
        "STAY at Yasmin, is a visionary project, offering residents a unique living experince addressing with its contemporary design. The gated community is located in one of the most commutable areas in Al Yasmin district of Riyadh. The development focuses on providing residents with a functional, aesthetic space complementing the needs of a modern family home, with sustainability features.  ",
        "The project features apartments in the midst of lush green landscaping used as a buffer to separate units, which creates a luminous and inviting atmosphere with distinct views. For entertainment, relaxation, the project includes amenities such as rooftop facilities(seating and barbeque stations), underground parking, gym, cub house, swimming pool, yoga studio, nursery, and a cinema. The property offers 33 units with maximum optimization of spaces, for increased resident comfort and well-being. The apartments range from one, two, and three bedrooms, featuring a comfortable living area, dining area, kitchen, and washrooms.",
    ],
    section_images: {},
    plans_cover: A1,
    plnas_description: "The plans efficiently utilize space, offering clearly defined areas for living, dining, cooking, and relaxation. Each space is designed to be adaptable and multifunctional, catering to diverse needs and activities.",
    location_dev: {
        latitude: 24.8275555556,
        longitude: 46.6301666667,
        google_maps_url: "https://maps.app.goo.gl/jjufDheFeTR7mkxc6",
    },
    images: importedImages.map((image, index) => {
        return {
            image: image,
            description: "",
            thumbnail: importedThumbnailImages[index],
        }
    }, []),
    hasPlansOverlay: false,
    hasPlans: true,
    hasLocation: true,
    forSale: false,
    plans: {},
    villaInof: {},
    globalDescriptionList: null,
    floorPlans: [
        {
            name: "A - Ground Floor",
            img: A1,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 Single bedroom",
                "8 Masterbedroom ",
                "9 Outdoor Area ",
                "10 Maid's room "
            ],
        },
        {
            name: "A - First Floor",
            img: A2,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 Single bedroom",
                "8 Masterbedroom ",
                "9 Outdoor Area ",
                "10 Maid's room "
            ],
        },
        {
            name: "A - Second Floor",
            img: A3,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 Single bedroom",
                "8 Masterbedroom ",
                "9 Outdoor Area ",
                "10 Maid's room "
            ],
        },
        {
            name: "B - Ground Floor",
            img: B1,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "8 Masterbedroom ",
                "10 Maid's room ",
            ],
        },
        {
            name: "B - First Floor",
            img: B2,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "8 Masterbedroom ",
                "10 Maid's room ",
            ],
        },
        {
            name: "B - Second Floor",
            img: B3,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "8 Masterbedroom ",
                "10 Maid's room ",
            ],
        },
        {
            name: "C - Ground Floor",
            img: C1,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 bedroom",
            ],
        },
        {
            name: "C - First Floor",
            img: C2,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 bedroom",
            ],
        },
        {
            name: "D - Ground Floor",
            img: D1,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 bedroom",
            ],
        },
        {
            name: "D - First Floor",
            img: D2,
            area: "",
            descriptionList: [
                "1 Living Area",
                "2 Dining Area",
                "3 Kitchen",
                "4 W.C",
                "5 Stairs ",
                "6 Lift ",
                "7 bedroom",
            ],
        },
    ],
};