import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import DoorButton from '../DoorButton/DoorButton';
import ArrowLink from '../ArrowLink/ArrowLink';
import Stat from '../Stat/Stat';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import stayStyle from '../../assets/stayStyle.json';
import mapsIcon from '../../assets/maps-icon.png';
import { APP_ROUTES, GOOGLE_MAPS_API_KEY } from '../../const/const';
import { keyframes } from "@emotion/react";
import { Reveal } from 'react-awesome-reveal';
import ImageGallery from "react-image-gallery";
import AutoPlaySilentVideo from '../Video/Video';

import './ProjectDetails.css';


const ProjectDetails = ({ project }) => {
    const { t } = useTranslation();


    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const isMobile = window.innerWidth <= 992;
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });
    const buttonRef = useRef(null);

    useEffect(() => {
        if (buttonRef.current) {
            const handleScroll = () => {
                const buttonRect = buttonRef.current.getBoundingClientRect();
                const isButtonVisible = buttonRect.top > 0 && buttonRect.bottom <= window.innerHeight;
                setShowStickyHeader(!isButtonVisible);
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);



    const mapContainerStyle = {
        width: isMobile ? '100%' : '80%',
        height: isMobile ? '300px' : '400px',
        marginBottom: '100px',
    };

    let projectMarker = {};

    if (project.hasLocation) {
        projectMarker = {
            position: {
                lat: project.location_dev.latitude,
                lng: project.location_dev.longitude,
            },
            visible: true,
            title: 'Drfah',
            icon: mapsIcon,
            animation: 2,
            onClick: () => {
                window.open(project.location_dev.google_maps_url, '_blank');
            }
        };
    }

    const enlarg = keyframes`
    from {
      transform: scale(0.9);
    }

    to {
      transform: scale(1);
    }
  `;

    return (
        <>
            <Navbar />

            <div className={`button-sticky-header ${showStickyHeader ? 'show' : ''}`}>
                <div className={'container-lg d-flex ' + (isMobile ? 'justify-content-end' : 'justify-content-start')}>
                    <DoorButton text={t('project_details.book_a_tour')} className={'project-details-book-a-tour'} href={APP_ROUTES.BOOK_A_TOUR + '?project_id=' + project.id} />
                </div>
            </div>

            <Row className="project-details-header">
                <Col xs={12} md={12} lg={5} className='order-last order-lg-first'>
                    <h3 className='project-details-header-title'>
                        {project.name}
                    </h3>
                    {project.forSale && (
                        <div ref={buttonRef}>
                            <DoorButton text={t('project_details.book_a_tour')} className={'project-details-book-a-tour'} href={APP_ROUTES.BOOK_A_TOUR + '?project_id=' + project.id} />
                        </div>
                    )}
                    {project.location && (
                        <p className='project-details-header-key'>
                            {t('project_details.location')}
                        </p>
                    )}
                    <p className='project-details-header-value'>
                        {project.location}
                    </p>

                    <p className='project-details-header-key'>
                        {t('project_details.residential_unit_type')}
                    </p>
                    <p className='project-details-header-value'>
                        {project.residential_Unit_Type}
                    </p>

                    {project.forSale && (
                        <>
                            <p className='project-details-header-key'>
                                {t('project_details.starting_at')}
                            </p>

                            <p className='project-details-header-value'>
                                {project.starting_price_text}
                            </p>
                        </>

                    )}
                    <p className='project-details-header-key'>
                        {t('project_details.scope')}
                    </p>
                    <p className='project-details-header-value'>
                        {project.scope}
                    </p>

                    <p className='project-details-header-key'>
                        {t('project_details.status')}
                    </p>

                    <p className='project-details-header-value'>
                        {project.status}
                    </p>

                    {project.team && project.team.length > 0 && (
                        <>
                            <p className='project-details-header-key'>
                                {t('project_details.team')}
                            </p>
                            <p className='project-details-header-value last'>
                                {project.team.map((teamMember, index) => (
                                    <React.Fragment key={index}>
                                        {teamMember}
                                        {index < project.team.length - 1 && <br />}
                                    </React.Fragment>
                                ))}
                            </p>
                        </>
                    )}

                </Col>

                <Col xs={12} md={12} lg={7} className='order-first order-lg-last'>
                    <h3 className='project-details-header-title-mobile'>
                        {project.name}
                    </h3>
                    {project.opener_video ? (
                        <AutoPlaySilentVideo video={project.opener_video} controls={true}
                            poster={project.main_images ? project.main_images[0] : null}
                            className="project-details-header-image" />
                    ) : (
                        <>
                            {project.main_images && project.main_images.length > 0 && (
                                <>
                                    {
                                        project.rotate_images != null && project.rotate_images === false ? (
                                            <img src={project.main_images[0]} className='project-details-header-image' />
                                        ) : (
                                            <ImageGallery
                                                additionalClass='project-details-header-image'
                                                originalHeight={'100%'}
                                                originalWidth={'100%'}
                                                originalClass={'project-details-header-image'}
                                                showThumbnails={false}
                                                showPlayButton={false}
                                                showFullscreenButton={false}
                                                showNav={false}
                                                autoPlay={true}
                                                startIndex={
                                                    project.images.findIndex((image) => image.image === project.main_images[0])
                                                }
                                                items={project.images.map((image, index) => {
                                                    return {
                                                        original: image.image,
                                                    }
                                                })} />
                                        )
                                    }
                                </>
                            )}
                        </>
                    )}
                </Col>
            </Row>

            <Row className='project-details-section align-items-start'>
                <Col xs={12} md={12} lg={5} className='order-last order-lg-first'>

                </Col>

                <Col xs={12} md={12} lg={7} className='order-first order-lg-last'>
                    {project.stats && (
                        <>
                            {Object.entries(project.stats).map(([key, value], index) => (
                                index % 2 === 0 && (
                                    <Row className='stats' key={index}>
                                        <Col xs={6} md={6} lg={6}>
                                            <Stat stat={t('project_details.' + key)} value={value.value} unit={value.unit} prefix={value.prefix ? value.prefix : ""} />
                                        </Col>
                                        {index + 1 < Object.entries(project.stats).length && (
                                            <Col xs={6} md={6} lg={6}>
                                                <Stat stat={t('project_details.' + Object.entries(project.stats)[index + 1][0])} value={Object.entries(project.stats)[index + 1][1].value} unit={Object.entries(project.stats)[index + 1][1].unit} prefix={Object.entries(project.stats)[index + 1][1].prefix ? Object.entries(project.stats)[index + 1][1].prefix : ""} />
                                            </Col>
                                        )}
                                    </Row>
                                )
                            ))}
                        </>
                    )}

                    {project.text_sections.map((textSection, index) => (
                        <>
                            <p key={index} className={`${project.section_images[index] ? "project-details-section-text-with-image" : "project-details-section-text"}`}>
                                {textSection}
                            </p>

                            {
                                project.section_images[index] && (
                                    <div className='project-details-section-images'>
                                        {project.section_images[index].map((image, index) => (
                                            <img key={index} src={image} className='project-details-section-image' />
                                        ))}
                                    </div>
                                )
                            }

                        </>
                    ))}

                    <Row className='more-images-holder'>
                        {project.main_images.length > 1 && (
                            <Col xs={6} md={6} lg={6}>
                                <Reveal keyframes={enlarg} duration={500}>
                                    <img src={project.main_images[1]} className='more-images'
                                        onClick={() => window.location.href = APP_ROUTES.PROJECT_GALLERY + '?project_id=' + project.id}
                                    />
                                </Reveal>
                            </Col>
                        )}
                        {project.main_images.length > 2 && (
                            <Col xs={6} md={6} lg={6}>
                                <Reveal keyframes={enlarg} duration={500}>
                                    <img src={project.main_images[2]} className='more-images'
                                        onClick={() => window.location.href = APP_ROUTES.PROJECT_GALLERY + '?project_id=' + project.id}
                                    />
                                </Reveal>
                            </Col>
                        )}
                        {project.images.length > 3 && (
                            <ArrowLink text={t('project_details.view_more')} className={'project-details-view-more'} href={APP_ROUTES.PROJECT_GALLERY + '?project_id=' + project.id} />
                        )}
                    </Row>
                </Col >

            </Row >

            {project.hasPlans && (
                <Row className='project-details-floor-plans'>
                    <Col xs={12} md={12} lg={6} style={{ textAlign: 'center', marginBottom: '100px' }} className='order-last order-lg-first'>
                        <img src={project.plans_cover} className='project-details-plans-cover'
                            onClick={() => window.location.href = APP_ROUTES.PROJECT_FLOOR_PLANS + '?project_id=' + project.id}
                        />
                    </Col>

                    <Col xs={12} md={12} lg={6} className='order-first order-lg-last'>
                        <h3 className='project-details-floor-plans-title'>
                            {t('project_details.floor_plans')}
                        </h3>

                        <p className='project-details-floor-plans-description'>
                            {project.plnas_description}
                        </p>

                        <ArrowLink text={t('project_details.explore_the_plans')} href={APP_ROUTES.PROJECT_FLOOR_PLANS + '?project_id=' + project.id} className={'project-details-explore-the-plans'} />
                    </Col>
                </Row>
            )}

            {project.hasLocation && (
                <Row className='project-details-nearest-landmarks'>
                    <Col xs={12} md={12} lg={6} className='order-last order-lg-first'>
                        {isLoaded && (
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                zoom={17}
                                center={projectMarker.position}
                                options={{
                                    styles: stayStyle,
                                    fullscreenControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                }}

                            >
                                <MarkerF {...projectMarker} />
                            </GoogleMap>
                        )}
                    </Col>

                    <Col xs={12} md={12} lg={6} className='order-first order-lg-last'>
                        <h3 className='project-details-nearest-landmarks-title'>
                            {t('project_details.nearby_landmarks')}
                        </h3>

                        <p className='project-details-nearest-landmarks-description'>
                            {project.nearby_description}
                        </p>

                        <ArrowLink text={t('project_details.explore_the_location')} href={project.location_dev.google_maps_url} className={'project-details-view-map'} />

                    </Col>
                </Row>
            )}

            <Footer />
        </>
    );
}

export default ProjectDetails;
